@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  padding-top: 70px;
}

/* Navbar 
======================= */
.navbar {
  background-color: white;
  padding-bottom: 0;
}

nav .container {
  position: relative;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.logo {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
}

.logo img {
  max-width: 100px;
}

.navbar-collapse {
  justify-content: center;
}

nav ul {
  gap: 20px;
}

nav a {
  text-decoration: none;
  color: rgb(33, 37, 41);
  font-weight: 600;
  position: relative;
}

nav a:hover {
  color: rgb(33, 37, 41);
}

nav a::after {
  background-color: rgb(33, 37, 41);
  bottom: -1px;
  content: "";
  height: 2px;
  left: 0px;
  position: absolute;
  transform: scaleX(0);
  transition: transform 0.3s ease 0s;
  width: 100%;
}

nav a:hover::after {
  transform: scaleX(1);
}

nav .right {
  position: absolute;
  right: 0%;
  top: 0;
  display: flex;
  align-items: center;
  height: 52.5px;
}

nav .right svg {
  fill: #69707D;
}

nav .right svg:hover {
  fill: rgb(33, 37, 41);
  cursor: pointer;
}

nav .avatar {
  max-width: 50px;
  border-radius: 50%;
  margin-left: 20px;
  border: 2px solid white;
}

nav .avatar:hover {
  border: 2px solid rgb(33, 37, 41);
}

button.navbar-toggler {
  border: none;
  padding: 0;
}

button.navbar-toggler:focus {
  box-shadow: none;
}

/* Home 
============================ */
.home {
  padding-top: 50px;
  overflow-x: hidden;
}

/* nav.navbar {
  display: none;
}

.three {
  position: relative;
  padding: 100px 0 1000px;
}

.three img {
  max-width: 500px;
}

.one {
  position: absolute;
  z-index: 10;
  left: 130px;
  top: 190px;
}

.two {
  position: absolute;
} */

.home .row-1 {
  height: 550px;
  position: relative;
}

.home h2 {
  font-weight: 700;
  font-size: 68px;
}

.home p,
.home a {
  font-weight: 600;
  font-size: 20px;
}

.home .row-1 p {
  width: 400px;
}

.home .row-1 .left .bottom {
  display: flex;
  align-items: center;
  gap: 40px;
  height: 200px;
}

.home .explore-more {
  font-weight: initial;
  padding: 10px;
  width: 200px;
  height: 55px;
  transform: skewX(-15deg);
  text-align: center;
}

.home a {
  color: initial;
}

.home .explore-more a {
  transform: skewX(15deg);
  text-align: center;
  text-decoration: none;
  color: white;
}

.home .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.home .right .img {
  width: 380px;
  height: 380px;
  position: relative;
}

.home .right .img img {
  position: absolute;
  width: 400px;
  bottom: 0;
  left: 20px;
  transition: 1s;
  opacity: 0;
}

.home .right .img img.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

.lastSlide {
  transform: translateX(-100vw);
}

.nextSlide {
  transform: translateX(200%);
}

.prev,
.next {
  position: absolute;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  padding: 0;
  border-radius: 5px;
  cursor: pointer;
  top: calc(50% - 16px);
}

.prev {
  left: -950px;
}

.next {
  right: -50px;
}

.home .row-2 {
  margin-bottom: 48px;
}

.home .top-picks {
  font-weight: initial;
  color: white;
  background-color: rgb(33, 37, 41);
  padding: 5px;
  width: 200px;
  height: 40px;
  transform: skewX(-15deg);
}

.home .top-picks p {
  transform: skewX(15deg);
  width: 190px;
  text-align: center;
}

.home .top-picks::before {
  content: '';
  width: 500px;
  height: 2px;
  background-color: #ccc;
  position: absolute;
  right: 220px;
  top: 50%;
}

.home .top-picks::after {
  content: '';
  width: 500px;
  height: 2px;
  background-color: #ccc;
  position: absolute;
  left: 220px;
  top: 50%;
}

.home .row-3 {
  text-align: center;
}

.home .row-3 div img {
  max-height: 385px;
}

.home .row-3 h5 {
  font-weight: 600;
}

.home .row-3 p {
  color: orange;
}

#faq {
  background-color: rgb(250, 250, 250);
  padding: 80px 0 30px;
}

#faq h2 {
  font-size: 56px;
  margin-bottom: 30px;
}

#faq p {
  max-width: 500px;
  margin-bottom: 50px;
}

#faq .contact-us {
  padding: 15px 80px 15px 30px;
  border-radius: 4px;
  background-color: #dacdf3;
  background-image: url('./images/Arrow-Black.svg'), url('./images/Arrow-Black.svg');
  background-position: -20% 50%, 80% 50%;
  background-size: auto, auto;
  background-repeat: no-repeat;
  transition: opacity 200ms ease, background-position 320ms ease, padding 320ms ease;
  color: black;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
}

#faq .contact-us:hover {
  padding-right: 30px;
  padding-left: 80px;
  background-position: 30px 50%, 120% 50%;
}

.faq-question-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: white;
  border: 1px solid rgb(219, 229, 230);
  border-radius: 10px;
  margin-bottom: 10px;
}

.faq-question-heading {
  padding: 20px;
  padding-right: 0;
}

.faq-question-heading:hover {
  cursor: pointer;
}

.faq-question-block h6 {
  display: inline;
  margin-left: 30px;
  font-weight: 600;
  font-size: 16px;
}

.faq-question-block p {
  margin-left: 73px;
  margin-bottom: 20px;
  margin-right: 20px;
}

#contact {
  text-align: center;
  position: relative;
  padding-top: 80px;
  padding-bottom: 100px;
}

#contact .background-line {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

#contact .background-line div {
  background-color: #fbfbfb;
  width: 2px;
}

#contact .background-circle {
  background: linear-gradient(#f5f1fb, #fbeff6);
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

#contact h2 {
  font-size: 56px;
}

#contact .col div {
  display: flex;
  align-items: center;
  border: 1px solid #a6a3aa;
  border-radius: 6px;
  background-color: white;
  padding: 4px;
  padding-left: 10px;
}

#contact .col div:hover {
  background-color: #f9f9fb;
}

#contact .form-control {
  border: none;
  background-color: transparent;
}

#contact .form-control:hover {
  cursor: pointer;
}

#contact .form-control:focus {
  box-shadow: none;
}

#contact img {
  max-width: 20px;
}

#contact textarea {
  height: 120px;
}

#contact button {
  background-color: #dacdf3;
  width: 100%;
  border: 1px solid #a6a3aa;
  box-shadow: 0 3px;
  transition: 0.5s background-color;
  font-weight: 600;
}

#contact button:hover {
  background-color: #c3b6da;
}

#contact button:active {
  box-shadow: none;
  transform: translateY(3px);
}

footer .container {
  border-top: 1px solid #ccc;
  padding: 30px;
}

footer .row h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.social-media {
  display: flex;
  gap: 20px;
}

.social-media a {
  background-color: #f8fbff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 24px;
  border: 1px solid #d7dee9;
  transition: border 320ms ease;
}

.social-media a:hover {
  border-color: #08104d;
}

.twitter-icon {
  background-image: url('./images/twitter-icon.svg');
}

.facebook-icon {
  background-image: url('./images/facebook-icon.svg');
}

.instagram-icon {
  background-image: url('./images/instagram-icon.svg');
}

.linkedin-icon {
  background-image: url('./images/linkedin-icon.svg');
}

footer .row img {
  max-width: 20px;
  margin-right: 10px;
}

footer a {
  text-decoration: none;
  color: inherit;
}

footer a:hover {
  text-decoration: underline;
}

footer a {
  text-decoration: none;
}

.footer {
  background-color: black;
  text-align: center;
  padding: 20px 0;
}

.footer div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.footer p {
  color: white;
  margin-bottom: 0;
  margin-top: 10px;
}

.shop {
  margin-bottom: 50px;
}

.shop .row>div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 465px;
  padding: 10px;
  border-right: 1px solid rgb(33, 37, 41);
  border-bottom: 1px solid rgb(33, 37, 41);
}

.shop img {
  max-height: 300px;
  max-width: 250px;
}

.shop img+div {
  width: 100%;
  text-align: center;
}

.shop p {
  width: 100%;
  color: orange;
  font-weight: 600;
  font-size: 20px;
  text-align: left;
  margin-bottom: 0;
  margin-top: 16px;
}

.players {
  padding-top: 50px;
}

.players .row {
  position: relative;
}

.players .left div {
  background: linear-gradient(#dae9ea, #dfe9e9);
  height: 200px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  gap: 10px;
  padding-right: 40px;
  cursor: pointer;
}

.players .left div:hover {
  background: linear-gradient(#b5c2c3, #dae9ea);
}

.players .left img {
  max-height: 100%;
}

.players .left a img {
  display: none;
  max-height: 190px;
}

.players .left h2 {
  flex-grow: 1;
}

.players .left p {
  font-family: 'Londrina Outline', cursive;
  font-size: 45px;
}

.players .right {
  height: 600px;
  position: fixed;
  right: 0;
  display: grid;
  grid-template-columns: repeat(6, 90px);
  grid-template-rows: 420px 1fr;
  justify-content: space-around;
  gap: 20px;
}

.players .right img {
  max-width: 100%;
  opacity: 0.5;
  transition: all 0.2s ease-in-out;
}

.players .right img:hover {
  opacity: 1;
}

.players .right a {
  max-height: 100%;
  grid-column: 2 / -1;
}

.players .right a img {
  max-height: 100%;
  opacity: 1;
}

@media (max-width: 1399.9px) {
  .prev {
    left: -785px;
  }

  .home .top-picks::before {
    width: 420px;
  }

  .home .top-picks::after {
    width: 420px;
  }

  .players .right {
    grid-template-columns: repeat(6, 80px);
    grid-template-rows: 350px 1fr;
  }
}

@media (max-width: 1199.9px) {
  .next {
    right: -30px;
  }

  .prev {
    left: -590px;
  }

  .home .row-1 .left .bottom {
    gap: 30px;
  }

  .home .top-picks::before {
    width: 320px;
  }

  .home .top-picks::after {
    width: 320px;
  }

  .home .row-3 div img {
    max-width: 275px;
  }

  .players .right {
    grid-template-columns: repeat(6, 60px);
    grid-template-rows: 350px 1fr;
  }
}

@media (max-width: 991.9px) {
  .home {
    padding-top: 30px;
  }

  .home h2 {
    font-size: 54px;
  }

  .home .row-1 p {
    width: 300px;
  }

  .home .row-1 .left .bottom {
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .home .right .img {
    height: 320px;
  }

  .home .right .img img {
    left: 0;
    max-width: 350px;
  }

  .next {
    right: -35px;
  }

  .prev {
    left: -400px;
  }

  .home .top-picks::before {
    width: 220px;
  }

  .home .top-picks::after {
    width: 220px;
  }

  .home .row-3 div img {
    max-height: 300px;
    max-width: 215px;
  }

  #faq h2 {
    font-size: 48px;
  }

  .faq-question-block p {
    margin-left: 31px;
  }

  #contact h2 {
    font-size: 48px;
  }

  .shop img {
    max-width: 200px;
  }

  .shop h2 {
    font-size: 24px;
  }

  .players .right {
    grid-template-columns: repeat(3, 60px);
    grid-template-rows: 350px 1fr 1fr;
    align-items: center;
    row-gap: 0;
  }

  .players .right a {
    grid-column: 1 / -1;
  }
}

@media (max-width: 767.9px) {
  nav ul {
    gap: 10px;
  }

  nav .right {
    right: 15%;
  }

  .home .row-1 {
    height: initial;
    text-align: center;
  }

  .home .row-1 p {
    width: initial;
  }

  .home .row-1 .left .bottom {
    flex-direction: row;
    align-items: center;
    transform: translateY(430px);
  }

  .home .right {
    transform: translateY(-50px);
    margin-bottom: 100px;
    justify-content: center;
  }

  .home .right .img img {
    max-width: 400px;
  }

  .next {
    right: -50px;
  }

  .prev {
    left: -50px;
  }

  .home .top-picks::before {
    width: 150px;
  }

  .home .top-picks::after {
    width: 150px;
  }

  .home .row-3 div img {
    max-height: 230px;
    max-width: 165px;
  }

  #faq {
    padding-top: 30px;
  }

  #faq .row {
    gap: 50px;
  }

  #faq h2 {
    margin-bottom: 20px;
  }

  #faq p {
    margin-bottom: 30px;
  }

  .shop .row>div {
    border-right-color: white;
    height: 180px;

    display: grid;
    grid-template-columns: 150px 1fr;
    padding-left: 20px;
    gap: 20px;
  }

  .shop img {
    max-height: 150px;
    max-width: 110px;
  }

  .shop img+div {
    width: initial;
  }

  .shop h2 {
    text-align: left;
    margin-bottom: 30px;
  }

  .players .left div {
    border: 1px solid black;
    justify-content: space-between;
  }

  .players .left a img {
    display: block;
  }

  .players .left h2 {
    display: none;
  }

  .players .left p {
    display: none;
  }

  .players .right {
    display: none;
  }
}

@media (max-width: 575.9px) {
  nav .avatar {
    max-width: 40px;
    margin-left: 10px;
    border: 2px solid rgb(33, 37, 41);
  }

  .home {
    padding-top: 0;
  }

  .home h2 {
    font-size: 38px;
  }

  .home p {
    font-size: initial;
  }

  .home .row-1 .left .bottom {
    transform: translateY(300px);
  }

  .home .explore-more {
    width: 150px;
    height: 50px;
  }

  .home .row-1 a {
    font-size: initial;
  }

  .home .right {
    padding: 0;
    transform: translateY(-100px);
    margin-bottom: 50px;
  }

  .home .right .img {
    height: 250px;
    width: 100%;
  }

  .home .right .img img {
    max-width: 300px;
    left: 20px;
  }

  .next {
    right: 0;
  }

  .prev {
    left: 0;
  }

  .home .row-2 {
    margin-bottom: 20px;
  }

  .home .top-picks::before {
    display: none;
  }

  .home .top-picks::after {
    display: none;
  }

  .home .row-3 {
    flex-direction: column;
  }

  #faq h2 {
    font-size: 42px;
  }

  #faq p {
    font-size: 16px;
  }

  #contact {
    overflow: hidden;
  }

  #contact .background-line .hidden-on-phone {
    display: none;
  }

  #contact .background-circle {
    top: 150px;
  }

  #contact h2 {
    font-size: 42px;
  }

  .players .left div {
    height: 150px;
    justify-content: space-around;
  }

  .players .left a img {
    max-height: 140px;
  }
}

@media (max-width: 359.9px) {
  body {
    width: 360px;
  }
}